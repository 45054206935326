import React from 'react';

import {
	Box,
} from '@mui/material'

import makeStyles from '@mui/styles/makeStyles';
import { maxHeight } from '../height';
const useStyles = makeStyles((theme: any) => ({
	wrapper: {
		height: maxHeight("140px")
	},
}))


export default function DataStudioReport(props: any) {
	const classes = useStyles();

	const { reportId, pageId } = props

	return (
		<Box className={classes.wrapper} >

			<iframe
				src={`https://lookerstudio.google.com/embed/reporting/${reportId}/page/${pageId}`}
				frameBorder={0}
				style={{ border: 0, width: "100%", height: "100%" }}
				allowFullScreen
				loading="eager"
			>

			</iframe>

		</Box >
	)
}
