import React from 'react';
import { useOutletContext } from 'react-router-dom';

import { Wrapper } from "@googlemaps/react-wrapper";

import {
	Box, CircularProgress,
} from '@mui/material'

import makeStyles from '@mui/styles/makeStyles';
require('dotenv').config()

const useStyles = makeStyles((theme: any) => ({
	wrapper: {
		display: "flex",
		minHeight: 200,
		height: "100%",
		flexDirection: "column",
	},
	progressWrapper: {
		display: 'flex',
		justifyContent: "center",
		marginBottom: 10,
		marginTop: 8,
		height: "55px",
	},
}))

const API_KEY = process.env.REACT_APP_GMAPS_API_KEY || ""

type LiveJobsProps = {
	jobsData: any[],
	skillsData: any[],
	loading: boolean,
	doUpdate: boolean,
	center?: any,
}

function checkUrl(url: string) {
	if (url.includes('http')) return url
	return 'https://' + url
}

const DEFAULT_CENTRE = {
	lat: 50.45,
	lng: -4.9
}
const DEFAULT_ZOOM = 8.5
const DEFAULT_BOUNDS = {
	"south": 50.09188031436388,
	"west": -5.691015625000007,
	"north": 50.80542950696618,
	"east": -4.108984375000007
}

export default function LiveJobsMap() {
	const classes = useStyles();

	const context = useOutletContext() as LiveJobsProps

	//#region state
	const [zoom, setZoom] = React.useState(DEFAULT_ZOOM); // initial zoom
	const [center, setCenter] = React.useState<google.maps.LatLngLiteral>(context.center || DEFAULT_CENTRE);
	const [bounds, setBounds] = React.useState<google.maps.LatLngBounds | null>(null);

	const [jobs, setJobs] = React.useState<any>([]);
	const [jobsReduced, setJobsReduced] = React.useState<any>([]);

	const [skills, setSkills] = React.useState<any>([]);
	const [skillsReduced, setSkillsReduced] = React.useState<any>([]);

	const [infos, setInfos] = React.useState<any>([]);
	//#endregion

	React.useEffect(() => {
		console.log("context", context)
		if ((context.jobsData || context.skillsData) && context.doUpdate && google !== undefined) {
			// console.log("mapping")
			// Bounds
			const bounds = new google.maps.LatLngBounds();
			let totalReduceList = 0

			if (context.jobsData.length !== 0) {

				const mapJobs = context.jobsData.map((job: any) => {
					return {
						position: new google.maps.LatLng(job.latLng ? job.latLng.latitude : undefined, job.latLng ? job.latLng.longitude : undefined),
						icon: "http://maps.google.com/mapfiles/ms/icons/orange-dot.png",
						// icon: "http://maps.google.com/mapfiles/kml/paddle/J.png",
						title: job.title,
						company: job.company,
						URL: job.URL,
						location: job.location,
						type: "Job"
					}
				})
				// console.log("mapJobs", mapJobs)
				setJobs(mapJobs)

				// Reduce jobs
				setJobsReduced(reduceList(mapJobs))

				// Get actual center
				// https://stackoverflow.com/questions/19304574/center-set-zoom-of-map-to-cover-all-visible-markers

				mapJobs.forEach((job) => {
					bounds.extend(job.position);
				})
				// console.log("bounds", bounds)

				totalReduceList += reduceList.length
			} else {
				setJobs([])
				setJobsReduced([])
				// setInfos([])
			}

			if (context.skillsData.length !== 0) {

				const mapSkills = context.skillsData.map((skill: any) => {
					return {
						position: new google.maps.LatLng(skill.latLng ? skill.latLng.latitude : undefined, skill.latLng ? skill.latLng.longitude : undefined),
						icon: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
						title: skill.course,
						company: skill.company,
						URL: skill.URL,
						location: skill.location,
						type: "Training"
					}
				})
				// console.log("mapSkills", mapSkills)
				setSkills(mapSkills)

				// Reduce jobs
				setSkillsReduced(reduceList(mapSkills))

				// Get actual center
				// https://stackoverflow.com/questions/19304574/center-set-zoom-of-map-to-cover-all-visible-markers

				mapSkills.forEach((skill) => {
					bounds.extend(skill.position);
				})
				// console.log("bounds", bounds)

				totalReduceList += reduceList.length
			} else {
				setSkills([])
				setSkillsReduced([])
				// setInfos([])
			}

			if (context.skillsData.length || context.jobsData.length) {
				//center the map to the geometric center of all markers
				const { lat, lng } = bounds.getCenter()
				// console.log("bounds", { lat: lat(), lng: lng() })
				setCenter({ lat: lat(), lng: lng() })

				setBounds(bounds)

				//remove one zoom level to ensure no marker is on the edge.
				if (totalReduceList > 1) {
					setZoom(zoom - 1);
					// console.log("zoom", zoom)
				} else {
					setZoom(1)
				}

				// set a minimum zoom 
				/* if (zoom > 15) {
					setZoom(15);
				} */
			} else {
				// Reset map
				setCenter(DEFAULT_CENTRE)
				setZoom(DEFAULT_ZOOM)
				const bounds = new google.maps.LatLngBounds(DEFAULT_BOUNDS)
				setBounds(bounds)
			}

			setInfos([])

		}
	}, [context.doUpdate])

	React.useEffect(() => {
		// console.log("center", context.center)
		setCenter(context.center || DEFAULT_CENTRE)
	}, [context.center])

	//#region functions
	// Reduce jobs and trainings to use when more than 1 element is in one place
	const reduceList = (list: Record<string, any>[]) => {
		const initialValue: any[] = [];
		const output = list.reduce(
			(previousValue, currentValue) => {
				// console.log("previousValue", previousValue)
				// console.log("currentValue", currentValue)

				const id = currentValue.position
				const found = previousValue[id] != null
				if (found) {
					previousValue[id].push(currentValue)
				} else {
					previousValue[id] = [currentValue]
				}

				return previousValue
			},
			initialValue
		);

		// console.log(output)
		return output
	}

	const content = (props: Record<string, any>) => {
		const { type, title, company, location, url, index, total } = props

		return `
		<style>
			.footer {
				display: flex;
				justify-content: space-between;
				margin-top: 5px;
			}
			#btnNext, #btnPrev {
				cursor: pointer;
			}
		</style>
		<div id="wrapper">
			<span><b>${type}</b>: ${title}</span>
			</br>
			<span><b>${type === "Job" ? "Company" : "Provider"}</b>: ${company}</span>
			</br>
			<span><b>Location</b>: ${location}</span>
			</br>
			<div class="footer">
				<span><a href="${checkUrl(url)}" target="_blank">Link<a/></span>
				${(total > 1) ? `<span>${index > 1 ? `<span id="btnPrev"><&nbsp;&nbsp;</span>` : ""}<strong>${index}/${total}</strong>${index < total ? `<span id="btnNext">&nbsp;&nbsp;></span>` : ""}</span>`
				: ""}
			</div>
		</div>`
	}

	const onMarkerClick = (marker: any) => {
		// Add info window at given position with content
		const { position, type, title, location, URL } = marker


		const markers = type === "Job" ? jobsReduced[position] : skillsReduced[position]
		// console.log("#Markers", markers)
		// console.log("#Markers", markers?.length)

		const index = 1
		const data = markers[index - 1]

		const info = {
			content: content({
				type: data.type,
				title: data.title,
				company: data.company,
				location: data.location,
				url: data.URL,
				total: markers ? markers.length : 0,
				index
			}),
			position,
			type,
			index,
		}
		setInfos([info])
	}

	function onInfoClickNextPrev(info: any, isNext: boolean): void {
		const { position, index, type } = info
		// console.log("Next", info)

		const markers = type === "Job" ? jobsReduced[position] : skillsReduced[position]
		// console.log("#Markers", markers)
		// console.log("#Markers", markers?.length)

		const newIndex = isNext ? index : index - 2
		const data = markers[newIndex]

		const newInfo = {
			content: content({
				type: data.type,
				title: data.title,
				company: data.company,
				location: data.location,
				url: data.URL,
				total: markers ? markers.length : 0,
				index: newIndex + 1
			}),
			position,
			type,
			index: newIndex + 1,
		}
		setInfos([newInfo])
	}

	const onIdle = (m: google.maps.Map) => {
		// console.log("onIdle", m);
		setZoom(m.getZoom()!);
		setCenter(m.getCenter()!.toJSON());
	};

	const onInfoWindowClose = (index: number) => {
		console.log("delete", index)
		infos.splice(index, 1)
	}
	//#endregion

	return (
		<Box className={classes.wrapper}>
			{context.loading &&
				<Box className={classes.progressWrapper}>
					<CircularProgress size="2rem" />
				</Box>
			}
			<Wrapper apiKey={API_KEY} /* render={render} */>
				<Map
					center={center}
					onIdle={onIdle}
					zoom={zoom}
					style={{ flexGrow: "1", height: "100%" }}
					bounds={bounds}
				>
					{jobs.map((job: any, i: number) => (
						<Marker key={i} position={job.position} {...job} onClick={onMarkerClick} />
					))}
					{skills.map((skill: any, i: number) => (
						<Marker key={i} position={skill.position} {...skill} onClick={onMarkerClick} />
					))}
					{infos.map((info: any, i: number) => (
						<InfoWindow
							key={i}
							onClose={() => onInfoWindowClose(i)}
							onClickNext={() => onInfoClickNextPrev(info, true)}
							onClickPrev={() => onInfoClickNextPrev(info, false)}
							{...info}
						/>
					))}
				</Map>
			</Wrapper>
		</Box>
	);
};

/* INFO WINDOW CONTENT */

/* MAP */
//#region Map
interface MapProps extends google.maps.MapOptions {
	style: { [key: string]: string };
	onClick?: (e: google.maps.MapMouseEvent) => void;
	onIdle?: (map: google.maps.Map) => void;
	children?: React.ReactElement<google.maps.MarkerOptions>[] | React.ReactElement<google.maps.MarkerOptions>;
	center: { lat: number, lng: number };
	zoom: number;
	bounds: google.maps.LatLngBounds | null;
}

const Map: React.FC<MapProps> = ({
	onClick,
	onIdle,
	children,
	style,
	center,
	zoom,
	bounds,
}) => {
	const ref = React.useRef<HTMLDivElement>(null);
	const [map, setMap] = React.useState<google.maps.Map>();

	React.useEffect(() => {
		if (ref.current && !map) {
			const newMap = new window.google.maps.Map(ref.current, { center, zoom });
			setMap(newMap);

			const legend = createLegend()

			if (newMap.controls && newMap.controls[window.google.maps.ControlPosition.LEFT_BOTTOM]) {
				newMap.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(legend);
			} else {
				console.log('Controls array not initialized');
			}
		}
	}, [ref, map, center, zoom]);

	React.useEffect(() => {
		if (bounds && map) {
			map.fitBounds(bounds)
		}
	}, [bounds]);

	React.useEffect(() => {
		if (map) {
			["click", "idle"].forEach((eventName) =>
				google.maps.event.clearListeners(map, eventName)
			);

			if (onClick) {
				map.addListener("click", onClick);
			}

			if (onIdle) {
				map.addListener("idle", () => onIdle(map));
			}
		}
	}, [map, onClick, onIdle]);

	function createLegend() {
		// Create the legend element
		const legend = document.createElement('div');
		legend.style.backgroundColor = '#fff';
		legend.style.border = '1px solid #000';
		legend.style.padding = '0px 10px';
		legend.style.margin = '5px';
		legend.style.fontSize = '14px';
		legend.style.fontFamily = 'Arial, sans-serif';

		const legendTitle = document.createElement('h4');
		legendTitle.textContent = 'Legend';
		legend.appendChild(legendTitle);

		const marker1 = document.createElement('p');
		const marker1Img = document.createElement('img');
		marker1Img.src = 'http://maps.google.com/mapfiles/ms/icons/orange-dot.png';
		marker1Img.style.width = "20px"
		marker1.textContent = ' Job';
		marker1.prepend(marker1Img);
		legend.appendChild(marker1);

		const marker2 = document.createElement('p');
		const marker2Img = document.createElement('img');
		marker2Img.src = 'http://maps.google.com/mapfiles/ms/icons/green-dot.png';
		marker2Img.style.width = "20px"
		marker2.textContent = ' Training';
		marker2.prepend(marker2Img);
		legend.appendChild(marker2);

		return legend
	}
	return (
		<>
			<div ref={ref} style={style} />
			{React.Children.map(children, (child) => {
				if (React.isValidElement(child)) {
					// set the map prop on the child component
					return React.cloneElement(child as React.ReactElement<any>, { map });
				}
			})}
		</>
	);
};
//#endregion

/* MARKER */
//#region Marker
interface MarkerProps extends google.maps.MarkerOptions {
	onClick?: (marker: google.maps.Marker) => void;
}
const Marker: React.FC<MarkerProps> = ({ onClick, ...options }) => {
	const [marker, setMarker] = React.useState<google.maps.Marker>();

	React.useEffect(() => {
		if (!marker) {
			setMarker(new google.maps.Marker());
		}

		// remove marker from map on unmount
		return () => {
			if (marker) {
				marker.setMap(null);
			}
		};
	}, [marker]);


	React.useEffect(() => {
		if (marker) {
			marker.setOptions(options);

			["click"].forEach((eventName) =>
				google.maps.event.clearListeners(marker, eventName)
			);

			if (onClick) marker.addListener("click", () => { onClick(marker) });
		}
	}, [marker, options, onClick]);

	return null;
};
//#endregion

/* INFO WINDOW */
//#region InfoWindow
interface InfoWindowProps extends google.maps.MarkerOptions {
	onClose?: () => void;
	onClickNext: () => ((this: GlobalEventHandlers, ev: MouseEvent) => any) | null;
	onClickPrev: () => ((this: GlobalEventHandlers, ev: MouseEvent) => any) | null;
}
const InfoWindow: React.FC<InfoWindowProps> = ({ onClose, onClickNext, onClickPrev, ...options }) => {
	const [infoWindow, setInfoWindow] = React.useState<google.maps.InfoWindow>();

	React.useEffect(() => {
		if (!infoWindow) {
			setInfoWindow(new google.maps.InfoWindow());
		}

		// remove marker from map on unmount
		return () => {
			if (infoWindow) {
				infoWindow.close()
			}
		};
	}, [infoWindow]);

	React.useEffect(() => {
		if (infoWindow) {
			infoWindow.setOptions({ ...options, maxWidth: 300, minWidth: 300 });

			["closeclick"].forEach((eventName) =>
				google.maps.event.clearListeners(infoWindow, eventName)
			);

			if (onClose) infoWindow.addListener("closeclick", onClose);

			google.maps.event.addListener(infoWindow, "domready", function () {

				const buttonNext = document.getElementById("btnNext")
				if (buttonNext != null) buttonNext.onclick = onClickNext

				const buttonPrev = document.getElementById("btnPrev")
				if (buttonPrev != null) buttonPrev.onclick = onClickPrev

			});
		}
	}, [infoWindow, options, onClose]);

	return null
}
//#endregion


