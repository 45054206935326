import React from 'react';

import {
	Box, Button, Typography,
} from '@mui/material'
import Logo from "../assets/worksearch_logo.svg";

import makeStyles from '@mui/styles/makeStyles';
import { maxHeight } from '../height';
import { signInWithGoogle } from '../services/firebase';
import { useNavigate } from 'react-router-dom';
const useStyles = makeStyles((theme: any) => ({
	wrapper: {
		height: maxHeight("0"),
		width: "100%",
		display: "flex",
		justifyContent: "center",
		textAlign: "center",
		backgroundColor: "#f6f5f5",
	},
	loginWrapper: {
		marginTop: 40,
		padding: 50,
		backgroundColor: "white",
		maxHeight: 300,
		borderRadius: 10,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
}))

const regex = /^[a-zA-Z0-9_.+-]+@hi9\.io$/;
function isHi9Email(email: string): boolean {
  return regex.test(email);
}

export default function AdminLogin(props: any) {
	const classes = useStyles();

	const navigate = useNavigate()

	const handleLoginWithGoogle = async () => {
		const result = await signInWithGoogle()

		console.log("result", result)
	
		if (result && result.user.email && isHi9Email(result.user.email)) {
			console.log("navigate to admin dashboard")
			navigate("/admin/dashboard")
		} else {
			alert("You are not authorized to access this page.")
			// TODO: show error message
		}
	}

	return (
		<Box className={classes.wrapper} >
			<Box className={classes.loginWrapper}>
				<img src={Logo} />
				<Typography sx={{ mt: 2 }}>Admin Login</Typography>
				<Button variant="contained" sx={{ mt: 4 }}
					onClick={handleLoginWithGoogle}
				>
					Login with Google
				</Button>
			</Box>
		</Box >
	)
}
