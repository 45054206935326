import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom';

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import defaultTheme from "./theme";
import { Box } from '@mui/material';

import MainLayout from "./layout/MainLayout";
import LiveJobsLayout from './layout/LiveJobsLayout';
import LiveJobsTable from './pages/LiveJobsTable';
import DataStudioReport from './pages/DataStudioReport';
import LiveJobsMap from './pages/LiveJobsMap';
import AdminLogin from './pages/AdminLogin';
import AdminDashboard from './pages/AdminDashboard';
import PasswordProtection from './pages/PasswordProtection';

import { load } from './services/localStorage';

import { isMobileOnly } from 'react-device-detect';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const HISTORIC_DATASTUDIO = [
	{
		county: "cornwall",
		reportId: "73396e39-b812-484f-9c32-0e671ad1816d",
		pages: {
			"jobs/summaryV1": "p_ldx2htn52c",
			"jobs/summaryV2": "5zi9C",
			"skills/mapTop50": "p_w8ghl5672c",
			"skills/map": "p_9c9on7p90c",
			"skills/skillTable": "p_52xqk7mxad",
			"skills/summary": "p_mr7i1tw82c",
			"qualifications/summary": "p_f49j660i1c",
		}
	},
	{
		county: "devon",
		reportId: "4f5bed99-a7eb-4e25-9805-61c6080547ef",
		pages: {
			"jobs/summaryV1": "p_ldx2htn52c",
			"jobs/summaryV2": "5zi9C",
			"skills/mapTop50": "p_w8ghl5672c",
			"skills/map": "p_9c9on7p90c",
			"skills/skillTable": "p_kwivm6nxad",
			"skills/summary": "p_mr7i1tw82c",
			"qualifications/summary": "p_f49j660i1c",
		}
	},
]

const LIVE_DATASTUDIO = [
	{
		county: "cornwall",
		reportId: "9613f28f-349d-41e1-ab83-c0657fb204af",
		pageId: "p_jrezkmkp5c"
	},
	/* {
		county: "devon",
		reportId: "9613f28f-349d-41e1-ab83-c0657fb204af",
		pageId: "p_jrezkmkp5c"
	}, */
]

const PrivateRoute = () => {
	const loggedIn = load()
	console.log("loggedIn", loggedIn)

	// TODO: logout after 3 days?
	// TOOD: check when the access was deleted but user is still loggedin

	return loggedIn ? <Outlet /> : <Navigate to="/password" />
}

function App() {

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={createTheme(defaultTheme)}>
				<CssBaseline />
				<Box>
					<Router>
						<Routes>
							<Route path="/" element={<PrivateRoute />}>
								<Route path="/" element={<MainLayout><Outlet /></MainLayout>}>
									<Route path="/" element={<Navigate to="/historical/cornwall" />} />

									{
										HISTORIC_DATASTUDIO.map((item: any) => {
											return (
												<Route path={`/historical/${item.county}`} element={<Outlet />}>
													<Route path={`/historical/${item.county}`} element={<Navigate to={`/historical/${item.county}/jobs/summaryV1`} />} />
													{
														Object.keys(item.pages).map((pagePath: string) => {
															return (
																<Route
																	key={pagePath}
																	path={pagePath}
																	element={
																		<DataStudioReport
																			reportId={item.reportId}
																			pageId={item.pages[pagePath]}
																		/>
																	}
																/>
															)
														})
													}
												</Route>
											)
										})
									}

									<Route path="/live" element={<LiveJobsLayout />}>
										<Route path="/live" element={<Navigate to="/live/map" />} />
										<Route path="map" element={<LiveJobsMap />} />
										<Route path="table" element={<LiveJobsTable />} />
									</Route>

									{
										LIVE_DATASTUDIO.map((item: any) =>{
											return <Route path={`/live/${item.county}/statistics`} element={<DataStudioReport reportId={item.reportId} pageId={item.pageId} />} />
										})
									}
								</Route>
							</Route>

							<Route path="/password" element={<PasswordProtection />} />

							<Route path="/admin" element={<AdminLogin />} />
							<Route path="/admin/dashboard" element={<AdminDashboard />} />
							<Route path='*' element={<Box>Not found</Box>} />
						</Routes>
					</Router>

					<Dialog
						open={isMobileOnly}
						aria-describedby="alert-dialog-description"
					>
						<DialogContent>
							<DialogContentText id="alert-dialog-description" sx={{ fontSize: "1.25rem" }}>
								Worksearch is not optimised for mobile. Please use a desktop browser.
							</DialogContentText>
						</DialogContent>
					</Dialog>
				</Box>
			</ThemeProvider>
		</StyledEngineProvider>
	);
}

export default App;
