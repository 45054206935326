import { isMobile, isIOS, isMobileOnly, isTablet } from 'react-device-detect';

export function maxHeight(minus: string): string {

	let height = 100
	if (window.matchMedia('(display-mode: fullscreen)').matches) {
		// PWA
		height = 100
	} else if (isMobileOnly && isIOS) {
		height = 88
	} else if (isMobileOnly) {
		height = 93
	} else if (isTablet) {
		height = 96
	}
	// console.log("height", isMobile, isIOS, height, minus)
	return minus !== "0" ? `calc(${height}vh - ${minus})` : `${height}vh`
}