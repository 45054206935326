import red from "@mui/material/colors/red";

// https://www.w3schools.com/colors/colors_picker.asp
const defaultTheme = {
	typography: {
		// useNextVariants: true,
		fontFamily: [
			"Bai Jamjuree", "Montserrat", 'Segoe UI', 'Roboto', 'Oxygen',
			'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
			'sans-serif'
		].join(",")
	},
	palette: {
		primary: {
			light: '#cc1100',
			main: '#6A0000',
			dark: '#990d00',
			contrastText: '#fff',
		},
		secondary: {
			light: '#ffffff',
			main: '#E4E4E4',
			dark: '#808080',
			contrastText: '#fff',
		},
		error: red,
		contrastThreshold: 3,
		tonalOffset: 0.2
	},
};

export default defaultTheme;
