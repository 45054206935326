import React, { useEffect } from 'react';

import Logo from "../assets/worksearch_logo.svg";

import {
	Box, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	Paper, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

import { maxHeight } from '../height';
import { addUser, deleteUser, fbAuth, getUsers, logout, onAuthStateChanged } from '../services/firebase';

import { useNavigate } from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme: any) => ({
	wrapper: {
		height: maxHeight("0"),
		width: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		textAlign: "center",
		paddingTop: 10,
		backgroundColor: "#f6f5f5",
	},
	dataWrapper: {
		height: "70%",
		width: '70%',
		marginTop: 20,
		margin: 10,
	},
	tableWrapper: {
		height: "100%",
	},
}))

//#region Table components
interface Data {
	id: string;
	userContact: string;
	password: string;
	actions: any,
}

interface ColumnData {
	dataKey: keyof Data;
	label: string;
	numeric?: boolean;
	width: number;
	type: string;
}

const columns: ColumnData[] = [
	{
		width: 120,
		label: 'User Contact',
		dataKey: 'userContact',
		type: "string",
	},
	{
		width: 120,
		label: 'Password',
		dataKey: 'password',
		type: "string",
	},
	{
		width: 20,
		label: '',
		dataKey: 'actions',
		type: "actions",
	},
];

//#endregion

type User = {
	id?: string,
	userContact: string,
	password: string,
}

export default function AdminDashboard() {
	const classes = useStyles();

	const navigate = useNavigate()

	const [data, setData] = React.useState<any[]>([])

	const [loading, setLoading] = React.useState(false)

	const [open, setOpen] = React.useState(false)
	const [newForm, setNewForm] = React.useState<User>({
		userContact: "",
		password: "",
	})

	const [confirmDelete, setConfirmDelete] = React.useState({
		open: false,
		id: ""
	})

	useEffect(() => {
		onAuthStateChanged(fbAuth, (user) => {
			if (!user) {
				navigate("/admin")
			}
		})

		async function fetchData() {
			setLoading(true)
			const users = await getUsers() as User[]
			setData(users || [])
			setLoading(false)
		}

		fetchData()
	}, [])

	/* New */
	const handleClose = () => {
		setOpen(false)
	}

	const generatePassword = () => {
		setNewForm({
			...newForm,
			password: Math.random().toString(36).slice(-8).toUpperCase()
		})
	}

	const handleAddNew = async () => {
		if (newForm.userContact.trim() === "" || newForm.password.trim() === "") {
			return
		}

		const newUserId = await addUser(newForm)

		setNewForm({ userContact: "", password: "" })
		setOpen(false)

		setData([...data, { ...newForm, id: newUserId }])
	}

	/* Delete */
	const handleCloseConfirm = () => {
		setConfirmDelete({
			open: false,
			id: ""
		})
	}

	const handleDelete = (id: string) => {
		// console.log("handleDelete", id)
		setConfirmDelete({
			open: true,
			id,
		})
	}

	const handleConfirmDelete = async () => {
		// console.log("handleConfirmDelete", confirmDelete)
		await deleteUser(confirmDelete.id)

		setData(data.filter((item) => item.id !== confirmDelete.id))

		setConfirmDelete({
			open: false,
			id: ""
		})
	}

	return (
		<Box className={classes.wrapper} >
			<Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%", mr: 5 }}
				onClick={logout}
			>
				<LogoutRoundedIcon />
				<Typography sx={{ ml: 1 }}>Logout</Typography>
			</Box>
			<Box sx={{ display: "flex", alignItems: "center" }}>
				<img src={Logo} width="70" />
				<Typography variant="h5" sx={{ ml: 1, fontWeight: "bold" }}>Admin Dashboard</Typography>
			</Box>
			<Box className={classes.dataWrapper}>
				<Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1.5 }}>
					<Button variant="contained" onClick={() => { setOpen(true) }}>
						<AddRoundedIcon sx={{ mr: 1 }} />
						Add new
					</Button>
				</Box>
				{loading ? "Loading ..." : (data.length ?
					<TableContainer component={Paper}>
						<Table sx={{ height: "100%" }} size="medium" aria-label="a dense table">
							<TableHead>
								<TableRow>
									{
										columns.map((column) => (
											<TableCell key={column.dataKey}>
												{column.label}
											</TableCell>
										))
									}
								</TableRow>
							</TableHead>
							<TableBody>
								{data.map((row) => (
									<TableRow
										key={row.id}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										{
											columns.map((column) => (
												<TableCell key={column.dataKey}>
													{
														(() => {
															switch (column.type) {
																case "date":
																	return row[column.dataKey] && (row[column.dataKey] as string).trim() !== "" ? new Date(row[column.dataKey] as string).toLocaleDateString() : "N/A"
																case "actions":
																	return (<Box sx={{ display: "flex", justifyContent: "flex-end", pr: 3 }}>
																		<DeleteRoundedIcon sx={{ cursor: "pointer" }} onClick={() => { handleDelete(row.id) }} />
																	</Box>)
																default:
																	return row[column.dataKey] || "N/A"
															}
														})()
													}
												</TableCell>
											))
										}
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer> : "No data to display")}

				<Box>

				</Box>
			</Box>

			{/* New user */}
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>New user</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						margin="dense"
						id="user-contact"
						label="User contact"
						type="text"
						fullWidth
						variant="outlined"

						value={newForm.userContact}
						onChange={(e) => { setNewForm({ ...newForm, userContact: e.target.value }) }}
					/>
					<TextField
						margin="dense"
						id="password"
						label="Password"
						type="text"
						fullWidth
						variant="outlined"

						value={newForm.password}
						onChange={(e) => { setNewForm({ ...newForm, password: e.target.value }) }}
					/>
					<Typography textAlign="right" sx={{ cursor: "pointer" }} onClick={generatePassword}>
						Generate a password?
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleAddNew}>Add</Button>
				</DialogActions>
			</Dialog>

			{/* Confirm delete */}
			<Dialog open={confirmDelete.open} onClose={handleCloseConfirm}>
				<DialogTitle>Confirm delete</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to delete this user?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseConfirm}>Cancel</Button>
					<Button onClick={handleConfirmDelete}>Confirm</Button>
				</DialogActions>
			</Dialog>
		</Box >
	)
}
