import React from 'react';
import { useOutletContext } from 'react-router-dom';

import {
	Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	Paper, Link, CircularProgress,
} from '@mui/material'
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';

import { TableVirtuoso, TableComponents } from 'react-virtuoso';

import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme: any) => ({
	wrapper: {
		marginTop: 10,
		height: "100%",
	},
	progressWrapper: {
		display: 'flex',
		justifyContent: "center",
		width: "100%",
		height: "100%",
		padding: 80
	},
	tableWrapper: {
		height: "100%",
		width: '100%',
		paddingBottom: 10,
	},
}))

type LiveJobsTableProps = {
	jobsData: any[],
	loading: boolean,
}

//#region Table components
interface Job {
	id: string;
	title: string;
	company: string;
	location: string;
	salary: number;
	jobType: string;
	hour: string;
	commuteTime: string,
	postDate: string;
	closeDate: string;
	URL: string;
}

interface ColumnData {
	dataKey: keyof Job;
	label: string;
	numeric?: boolean;
	width: number;
	type: string;
}

const columns: ColumnData[] = [
	{
		width: 200,
		label: 'Title',
		dataKey: 'title',
		type: "string",
	},
	{
		width: 120,
		label: 'Company',
		dataKey: 'company',
		type: "string",
	},
	{
		width: 120,
		label: 'Location',
		dataKey: 'location',
		type: "string",
	},
	{
		width: 120,
		label: 'Salary',
		dataKey: 'salary',
		numeric: true,
		type: "number",
	},
	{
		width: 120,
		label: 'Job Type',
		dataKey: 'jobType',
		type: "string",
	},
	{
		width: 120,
		label: 'Hours',
		dataKey: 'hour',
		type: "string",
	},
	{
		width: 120,
		label: 'Commute time',
		dataKey: 'commuteTime',
		type: "string",
	},
	{
		width: 120,
		label: 'Post date',
		dataKey: 'postDate',
		type: "date",
	},
	{
		width: 120,
		label: 'Close date',
		dataKey: 'closeDate',
		type: "date",
	},
	{
		width: 120,
		label: 'Link',
		dataKey: 'URL',
		type: "url",
	},

];

const VirtuosoTableComponents: TableComponents<Job> = {
	Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
		<TableContainer component={Paper} {...props} ref={ref} />
	)),
	Table: (props) => (
		<Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
	),
	TableHead,
	TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
	TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
		<TableBody {...props} ref={ref} />
	)),
};

function fixedHeaderContent() {
	return (
		<TableRow>
			{columns.map((column) => (
				<TableCell
					key={column.dataKey}
					variant="head"
					align={column.numeric || false ? 'right' : 'left'}
					style={{ width: column.width }}
					sx={{
						backgroundColor: 'background.paper',
					}}
				>
					{column.label}
				</TableCell>
			))}
		</TableRow>
	);
}

function rowContent(_index: number, row: Job) {
	return (
		<React.Fragment>
			{columns.map((column) => (
				<TableCell
					key={column.dataKey}
					align={column.numeric || false ? 'right' : 'left'}
				>
					{
						(() => {
							switch (column.type) {
								case "date":
									return row[column.dataKey] && (row[column.dataKey] as string).trim() !== "" ? new Date(row[column.dataKey] as string).toLocaleDateString() : "N/A"
								case "url":
									return (<Link href={row[column.dataKey] as string} target="_blank" rel="noopener" underline="none">
										<OpenInNewRoundedIcon />
									</Link>)
								default:
									return row[column.dataKey] || "N/A"
							}
						})()
					}
				</TableCell>
			))}
		</React.Fragment>
	);
}
//#endregion

export default function LiveJobsTable() {
	const classes = useStyles();

	const context = useOutletContext() as LiveJobsTableProps

	return (
		<Box className={classes.wrapper} >
			{context.loading ? (
				<Box className={classes.progressWrapper}>
					<CircularProgress size="3rem" />
				</Box>
			) : context.jobsData && context.jobsData.length !== 0 ? (<Paper className={classes.tableWrapper}>
				<TableVirtuoso
					data={context.jobsData || []}
					components={VirtuosoTableComponents}
					fixedHeaderContent={fixedHeaderContent}
					itemContent={rowContent}
				/>
			</Paper>) : ""}
		</Box >
	)
}
