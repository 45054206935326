import React, { useState } from 'react';

import {
	Box, TextField, Radio, RadioGroup, FormControlLabel, Grid, Alert,
	Typography, Checkbox, FormControl, InputLabel, Select, MenuItem, Switch,
} from '@mui/material'

import SendRoundedIcon from '@mui/icons-material/SendRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import MicRoundedIcon from '@mui/icons-material/MicRounded';

import APIService from "../api";

import makeStyles from '@mui/styles/makeStyles';
import { Outlet } from 'react-router-dom';
import { maxHeight } from '../height';
const useStyles = makeStyles((theme: any) => ({
	wrapper: {
		display: "flex",
		flexDirection: "column",
		height: maxHeight("120px")
	},
	searchBoxWrapper: {
		padding: "5px 20px",
	},
	outletWrapper: {
		flexGrow: 1,
		paddingBottom: 10,
	},
	noResultWrapper: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: 55,
	}
}))

function isEmptyOrSpaces(str: string) {
	return str === null || str === "" || str.trim() === ""
}

type LiveJobsForm = {
	query: string,
	location: string,
	commuteTime: string,
	commuteMethod: string,
	titleOnly: boolean,
	month: "live" | string,
	departureTime: number,
}

const MONTHS = [
	{ value: "live", label: "Current" },
	{ value: "202402", label: "Feb 2024" },
	{ value: "202401", label: "Jan 2024" },
	{ value: "202312", label: "Dec 2023" },
	{ value: "202311", label: "Nov 2023" },
	{ value: "202310", label: "Oct 2023" },
	{ value: "202309", label: "Sep 2023" },
	{ value: "202308", label: "Aug 2023" },
	{ value: "202307", label: "Jul 2023" },
	{ value: "202306", label: "Jun 2023" },
	{ value: "202305", label: "May 2023" },
	{ value: "202304", label: "Apr 2023" },
	{ value: "202303", label: "Mar 2023" },
	{ value: "202302", label: "Feb 2023" },
]

const COMMUTE_METHODS = [
	{ value: "TRANSIT", label: "Transit" },
	{ value: "DRIVING", label: "Car" },
	{ value: "CYCLING", label: "Bike" },
	{ value: "WALKING", label: "Walk" },
]

export default function LiveJobsLayout() {
	const classes = useStyles();

	//#region state
	const [form, setForm] = React.useState<LiveJobsForm>({
		query: "",
		location: "",
		commuteTime: "",
		commuteMethod: "",
		titleOnly: true,
		month: "live",
		departureTime: (new Date()).getHours(),
	});

	const [loading, setLoading] = React.useState(false);
	const [doUpdate, setDoUpdate] = React.useState(false);
	const [locationCoord, setLocationCoord] = React.useState<any>(null);

	const [jobsData, setJobsData] = React.useState<any[] | null>(null);
	const [skillsData, setSkillsData] = React.useState<any[] | null>(null);
	const [formError, setFormError] = React.useState<boolean>(false);
	const [error, setError] = React.useState<string | null>(null);

	const [isAdvanced, setIsAdvanced] = useState(false)
	const [searchQuery, setSearchQuery] = useState("")
	/* 
	health jobs in Exeter within 30 min walking distance at 6pm
	Developer in truro last month
	jobs within 1 hour commute of TR26 2EB
	jobs in plymouth within half an hour commute if I depart at 10am
	*/
	const [searchQueryError, setSearchQueryError] = useState<string | boolean>(false)

	const [stt, setSTT] = useState(false)
	//#endregion

	//#region functions

	const handleEnterPress = (e: any) => {
		if (e.key === "Enter") {
			e.preventDefault();
			handleSearch(form)
		}
	}

	const handleSearch = async (form: LiveJobsForm) => {
		console.log("Search", form);

		setError(null)
		setDoUpdate(false)

		try {

			if (isEmptyOrSpaces(form.location)) {
				setFormError(true)
				setError("Please specify the location!")
				return
			}

			setJobsData(null)
			setSkillsData(null)
			setFormError(false)
			setLoading(true)

			if (isEmptyOrSpaces(form.location)) {
				// Quick search
				console.log("Quick search")
				setLocationCoord(null)

				const request = {
					query: form.query,
					count: 100,
					titleOnly: form.titleOnly,
					month: form.month,
				}

				// Jobs
				const jobsResult = await APIService.quickJobSearch(request)

				setJobsData(jobsResult.jobs)

				// Jobs
				const skillsResult = await APIService.quickSkillSearch(request)
				console.log("skillsResult", skillsResult)

				setSkillsData(skillsResult.skills)

				setLoading(false)
			} else {
				// Commute search
				console.log("Commute search")

				// If commute time is empty, set to 60 mins + if commute method is empty, set to transit
				const commuteTime = isEmptyOrSpaces(form.commuteTime) ? "60" : form.commuteTime
				const commuteMethod = isEmptyOrSpaces(form.commuteMethod) ? "DRIVING" : form.commuteMethod

				// Get coordinates
				const { coordinates } = await APIService.getLocationCoordinates({ location: form.location.trim() })
				console.log("coordinates", coordinates)

				// If no coordinates, set error
				if (!coordinates) {
					setError("Location not found")
					setLoading(false)
					return
				}

				const { id, county, lat, long } = coordinates

				setLocationCoord({
					lat, lng: long,
				})

				setForm({
					...form,
					commuteTime,
					commuteMethod,
					location: id,
				})

				const request = {
					keyword: form.query,
					seconds: (parseInt(commuteTime)) * 60,
					latitude: lat,
					longitude: long,
					commuteMethod,
					titleOnly: form.titleOnly,
					count: 100,
					month: form.month,
					departureTime: [form.departureTime, 0, 0],
					county,
				}
				// Get jobs
				const jobsResult = await APIService.commuteSearch(request)

				setJobsData(jobsResult.jobs)

				// Get skills
				const skillsResult = await APIService.commuteSkillsJobSearch(request)

				setSkillsData(skillsResult.skills)

				setLoading(false)

			}

			setDoUpdate(true)
		} catch (error: any) {
			setError(error.message + ". Please try again")
			setJobsData(null)
			setSkillsData(null)
			setLoading(false)
		}
	}

	const handleExtractSearchParams = async (searchQuery: string) => {
		setError(null)

		if (isEmptyOrSpaces(searchQuery)) {
			return setSearchQueryError("Required")
		}

		setLoading(true)
		try {

			const { parameters } = await APIService.extractQueryParameters({ query: searchQuery })
			console.log("parameters", parameters)

			if (!parameters) {
				throw new Error("Error")
			}

			const { commuteMethod, commuteTime, departureTime, jobTitle, month, postCodeOrTown, titleOnly } = parameters

			if (isEmptyOrSpaces(postCodeOrTown)) {
				setError("Please specify a location in the search query!")
				setLoading(false)
				return
			}

			const months = MONTHS.map((month: any) => month.value)
			const commuteMethods = COMMUTE_METHODS.map((method: any) => method.value)

			const form = {
				query: jobTitle || "",
				location: postCodeOrTown,
				commuteTime: commuteTime < 60 ? commuteTime : "",
				commuteMethod: commuteMethods.includes(commuteMethod) ? commuteMethod : "",
				titleOnly: titleOnly? titleOnly : true,
				month: months.includes(month) ? month : "live",
				departureTime: departureTime || (new Date()).getHours(),
			}
			setForm(form)
			setLoading(false)
			handleSearch(form)
		} catch (error: any) {
			setError(error.message + ". Please try again")
			setJobsData(null)
			setSkillsData(null)
			setLoading(false)
		}
	}

	function startSTT() {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
		if (SpeechRecognition === undefined) {
			alert("Sorry, your browser doesn't support speech to text!")
			return
		}
		const recognition = new SpeechRecognition()

		// This runs when the speech recognition service starts
		recognition.onstart = function () {
			setSTT(true)
		}

		recognition.onspeechend = function () {
			setSTT(false)
			recognition.stop()
		}

		// This runs when the speech recognition service returns result
		recognition.onresult = function (event: any) {
			const transcript = event.results[0][0].transcript
			const confidence = event.results[0][0].confidence
			console.log(transcript, confidence)
			setSearchQuery(transcript)
			handleExtractSearchParams(transcript)
		}
		// start recognition
		recognition.start()
	}

	//#endregion

	return (
		<Box className={classes.wrapper} >
			<Box className={classes.searchBoxWrapper}>
				{/* TODO: clear inputs */}
				<Box sx={{ mb: 1 }}>
					<Grid component="label" container alignItems="center" spacing={1} justifyContent="flex-end" >
						{/* <Grid item>
							<Typography variant="subtitle2">Simple</Typography>
						</Grid> */}
						<Grid item>
							<Switch
								disabled={loading}
								checked={isAdvanced}
								onChange={(event) => {
									setIsAdvanced(event.target.checked)
								}}
								size="medium"
							/>
						</Grid>
						<Grid item>
							<Typography variant="subtitle2">Advanced</Typography>
						</Grid>
					</Grid>
				</Box>
				{!isAdvanced ? (
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<TextField
							id="search-query" name="search-query"
							variant="outlined"
							fullWidth
							placeholder="Please provide your search query including relevant details such as location, commute information, and month"
							sx={{ mr: 1 }}

							onKeyDown={(e) => {
								if (e.key === "Enter") {
									e.preventDefault();
									handleExtractSearchParams(searchQuery)
								}
							}}

							error={searchQueryError !== false}
							helperText={searchQueryError}

							value={searchQuery}
							onChange={(e) => {
								setSearchQuery(e.target.value);
								setFormError(false)
							}}
						/>
						<SendRoundedIcon
							fontSize="large" sx={{ cursor: "pointer" }}
							onClick={() => { handleExtractSearchParams(searchQuery) }}
						/>
						<MicRoundedIcon
							fontSize="large"
							sx={{ cursor: "pointer", color: stt ? "red" : "inherit" }}
							onClick={startSTT}
						/>
					</Box>) : (
					<Box>
						<Box sx={{ display: "flex", alignItems: "center" }}>
							<TextField
								id="query" variant="outlined"
								fullWidth
								placeholder="Type your search query here..."
								sx={{ mr: 1 }}

								onKeyDown={handleEnterPress}

								value={form.query}
								onChange={(e) => {
									setForm({ ...form, query: e.target.value });
									setFormError(false)
								}}
							/>
							<SendRoundedIcon
								fontSize="large" sx={{ cursor: "pointer" }}
								onClick={() => { handleSearch(form) }}
							/>
							<FormControlLabel
								label="Title only"
								sx={{ width: "150px", ml: 1 }}
								control={
									<Checkbox
										checked={form.titleOnly}
										onChange={(event) => {
											setForm({ ...form, titleOnly: event.target.checked });
											setFormError(false)
										}}
										inputProps={{ 'aria-label': 'controlled' }}
									/>
								}
							/>
						</Box>
						<Grid container spacing={2} sx={{ mt: 1, alignItems: "center" }}>
							<Grid item xs={12} sm={4} md={1.5}>
								<FormControl fullWidth>
									<InputLabel id="month-select-label">Month</InputLabel>
									<Select
										labelId="month-select-label"
										id="month-select"
										value={form.month}
										label="Month"
										onChange={(e) => { setForm({ ...form, month: e.target.value as string }); }}
									>
										{
											MONTHS.map((month: any) => {
												return <MenuItem value={month.value} key={month.value}>{month.label}</MenuItem>
											})
										}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={4} md={2}>
								<FormControl fullWidth>
									<InputLabel id="month-select-label">Departure hour</InputLabel>
									<Select
										labelId="departure-hour-select-label"
										id="month-select"
										value={form.departureTime}
										label="Departure hour"
										onChange={(e) => { setForm({ ...form, departureTime: e.target.value as number }); }}
									>
										{Array.from(Array(24).keys()).map((hour: number) => {
											return (
												<MenuItem value={hour} key={hour}>{hour}</MenuItem>
											)
										})}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={4} md={2}>
								<TextField
									id="postcode-town" variant="outlined" label="Postcode / Town"
									sx={{ mr: 1 }}
									fullWidth

									onKeyDown={handleEnterPress}

									value={form.location}
									onChange={(e) => { setForm({ ...form, location: e.target.value }) }}

									error={formError}
								// helperText={formError ? "Location cannot be empty" : ""}
								/>
							</Grid>
							<Grid item xs={12} sm={4} md={2}>
								<TextField
									id="commute-time" variant="outlined" label="Commute time (mins)"
									type="number"
									fullWidth
									InputProps={{ inputProps: { min: 0, max: 60 } }}
									sx={{ mr: 1 }}

									onKeyDown={handleEnterPress}

									value={form.commuteTime}
									onChange={(e) => { setForm({ ...form, commuteTime: e.target.value }) }}
								/>
							</Grid>
							<Grid item xs={12} md={4.5}>
								<RadioGroup
									row
									aria-labelledby="commute-methods"
									name="commute-method"
									sx={{ justifyContent: "center" }}

									value={form.commuteMethod}
									onChange={(e) => { setForm({ ...form, commuteMethod: e.target.value }) }}
								>
									{
										COMMUTE_METHODS.map((method: any) => {
											return <FormControlLabel value={method.value} key={method.value} control={<Radio />} label={method.label} />
										})
									}
								</RadioGroup>
							</Grid>
						</Grid>
					</Box>
				)}

				{/* TODO: separate jobs from skills error */}
				{error && <Alert severity="error" sx={{ mt: 2, height: "55px" }}>{error}</Alert>}

				<Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
					{jobsData && jobsData.length !== 0 ?
						<Box sx={{ display: "flex", justifyContent: "center", height: "55px", mr: 2 }}>
							<Alert severity="success">{jobsData.length} jobs found!</Alert>
						</Box>
						: jobsData ? (
							<Box className={classes.noResultWrapper} sx={{ mr: 2 }}>
								<SearchRoundedIcon fontSize="large" sx={{ mr: 1 }} />
								<Typography>
									No jobs found
								</Typography>
							</Box>
						) : ""}

					{skillsData && skillsData.length !== 0 ?
						<Box sx={{ display: "flex", justifyContent: "center", height: "55px" }}>
							<Alert severity="success">{skillsData.length} trainings found!</Alert>
						</Box>
						: skillsData ? (
							<Box className={classes.noResultWrapper}>
								<SearchRoundedIcon fontSize="large" sx={{ mr: 1 }} />
								<Typography>
									No trainings found
								</Typography>
							</Box>
						) : ""}
				</Box>
			</Box>

			<Box className={classes.outletWrapper}>
				<Outlet context={{ jobsData, skillsData, loading, doUpdate, center: locationCoord }} />
			</Box>
		</Box >
	)
}
