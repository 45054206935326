import React from 'react';

import {
	Box, TextField, Typography, IconButton, Alert,
} from '@mui/material'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

import Logo from "../assets/worksearch_logo.svg";

import APIService from "../api";

import { useNavigate } from 'react-router-dom';

import { maxHeight } from '../height';
import makeStyles from '@mui/styles/makeStyles';
import { save } from '../services/localStorage';
const useStyles = makeStyles((theme: any) => ({
	wrapper: {
		height: maxHeight("0"),
		width: "100%",
		display: "flex",
		justifyContent: "center",
		textAlign: "center",
		backgroundColor: "#f6f5f5",
	},
	passwordWrapper: {
		marginTop: 40,
		padding: 40,
		backgroundColor: "white",
		maxHeight: 350,
		borderRadius: 10,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
}))

function isEmptyOrSpaces(str: string) {
	return str === null || str === "" || str.trim() === ""
}

export default function PasswordProtection(props: any) {
	const classes = useStyles();

	const navigate = useNavigate()

	const [password, setPassword] = React.useState("")
	const [error, setError] = React.useState<string | null>(null)

	const handleEnterPress = (e: any) => {
		if (e.key === "Enter") {
			e.preventDefault();
			handlePasswordCheck()
		}
	}

	const handlePasswordCheck = async () => {
		if (isEmptyOrSpaces(password)) return

		// console.log("handlePasswordCheck", password)
		const result = await APIService.checkPassword({ password })
		// console.log("result", result)
		if (result.found) {
			save()
			navigate("/")
		} else {
			setError("Wrong Password. Please try again.")
		}
	}

	return (
		<Box className={classes.wrapper} >
			<Box className={classes.passwordWrapper}>
				<img src={Logo} />
				<Typography sx={{ mt: 2 }}>Password</Typography>
				{error && <Alert sx={{ mt: 2 }} severity="error">{error}</Alert>}
				<Box sx={{ mt: 4 }}>
					<TextField
						id="password" label="Password" variant="outlined"

						onKeyDown={handleEnterPress}

						value={password}
						onChange={(e) => {
							setPassword(e.target.value);
							setError(null)
						}}
					/>
					<IconButton aria-label="enter" onClick={handlePasswordCheck}>
						<CheckCircleRoundedIcon fontSize="large" color="primary" />
					</IconButton>
				</Box>
			</Box>
		</Box >
	)
}
