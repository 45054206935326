
export function save(key: string = "loggedIn") {
	localStorage.setItem(key, (new Date()).toDateString())
}

export function load(key: string = "loggedIn") {
	return localStorage.getItem(key)
}

export function remove(key: string = "loggedIn") {
	return localStorage.removeItem(key)
}