import React from 'react';

import Logo from "../assets/worksearch_logo.svg";
import Hi9Logo from "../assets/Hi9_logo.png";

import {
	Box, Typography, Drawer, Link, Icon, Divider,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import { useNavigate } from "react-router-dom";
import { remove } from '../services/localStorage';

import { maxHeight } from '../height';

import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme: any) => ({
	wrapper: {
		display: "flex",
		width: "100%",
		height: maxHeight("0"),
	},
	leftSection: {
		backgroundColor: theme.palette.primary.main,
		height: "100%",
		display: "flex",
		color: "white",
	},
	rightSection: {
		backgroundColor: theme.palette.primary.main,
		display: "flex",
		height: "100%",
		color: "white",
		padding: "20px 20px",
		overflow: "auto",
	},
	nonDrawerSection: {
		width: 400,
		minWidth: 400,
		[theme.breakpoints.down('md')]: {
			display: "none",
		},
		[theme.breakpoints.down(1050)]: {
			width: 160,
			minWidth: 160,
		},
		[theme.breakpoints.down(2000)]: {
			width: 250,
			minWidth: 250,
		},
	},
	drawerSection: {
		width: 400,
		height: "100%",
		[theme.breakpoints.down(2000)]: {
			width: 250,
		},
	},
	hi9Logo: {
		marginTop: 5,
		width: 70,
		[theme.breakpoints.down('md')]: {
			width: 70,
		},
		[theme.breakpoints.down(1050)]: {
			width: 70,
		},
	},
	popularPrompt: {
		marginBottom: 10,
		cursor: "pointer",
		display: "flex",
	},
	middleSection: {
		height: "100%",
		flexGrow: 1,
		display: "flex",
		flexDirection: "column",
		padding: "10px 20px",
		[theme.breakpoints.down('md')]: {
			padding: 0,
		},
	},
	burgerMenuIcon: {
		cursor: "pointer",
		display: "none",
		[theme.breakpoints.down('md')]: {
			display: "block",
		},
	},
	topWrapper: {
		marginTop: 5,
		display: "flex",
		justifyContent: "flex-start",
		[theme.breakpoints.down('md')]: {
			justifyContent: "space-between"
		},
	},
}))

const VERSION = "1.5.2"

const LogoWidth = 80

const RIGHT_ROUTES = [
	{ text: "Historic jobs within 1h commute of Cornwall", path: "/historical/cornwall" },
	{ text: "Historic jobs within 1h commute of Devon", path: "/historical/devon" },
	{ text: "Search", path: "/live" },
]

const BASE_URL = "https://www.hi9.io"
const LINKS = [
	{ name: "Blog", link: "/blog", icon: "public" },
	{ name: "Data Policy and Privacy", link: "/worksearch/worksearch-data-policy", icon: "public" },
	{ name: "Contact Us", link: "/worksearch/worksearch-contact-us", icon: "public" },
	{ name: `Version ${VERSION}`, link: "/blog", icon: "info" },
]

/* 
Left drawer
*/
const HISTORIC_SECTIONS = [
	{
		name: "Jobs",
		path: "/jobs",
		pages: [
			{ name: "Summary V1", path: "/summaryV1" },
			{ name: "Summary V2", path: "/summaryV2" },
		]
	},
	{
		name: "Skills",
		path: "/skills",
		pages: [
			{ name: "Summary", path: "/summary" },
			{ name: "Map (Top 50)", path: "/mapTop50" },
			{ name: "Map", path: "/map" },
			{ name: "Table", path: "/skillTable" },
		]
	},
	{
		name: "Qualifications",
		path: "/qualifications",
		pages: [
			{ name: "Summary", path: "/summary" },
		]
	},
]
const LEFT_ROUTES = [ // Connecting right links to left links
	{
		path: "/live",
		pages: [
			{ name: "Map", path: "/map" },
			{ name: "Table", path: "/table" },
			{ name: "Cornwall Statistics", path: "/cornwall/statistics" },
			// { name: "Devon Statistics", path: "/devon/statistics" },
		]
	},
	{
		path: "/historical/cornwall",
		sections: HISTORIC_SECTIONS
	},
	{
		path: "/historical/devon",
		sections: HISTORIC_SECTIONS
	},
]

function LeftSideRoutes(props: any) {
	const routes = LEFT_ROUTES.find((route) => window.location.pathname.includes(route.path))
	// console.log("routes", routes)

	function PagesHtml(props: any) {
		const { pages, path } = props

		return (
			<Box>
				{pages.map((page: any) => {
					const fullPath = path + page.path

					// console.log(window.location.pathname, fullPath)
					const isCurrentPath = window.location.pathname === fullPath

					return (
						<Box
							key={page.name}
							onClick={() => { props.navigate(fullPath) }}
							sx={{ textDecoration: "none", color: "white" }}
						>
							<Box sx={{ display: "flex", alignItems: "center", mb: 1, pl: isCurrentPath ? 1 : 4.5, cursor: "pointer" }}>
								{isCurrentPath && <PlayArrowIcon fontSize="small" sx={{ mr: 1 }} />}
								<Typography variant="body1" sx={{ fontWeight: isCurrentPath ? "bold" : "regular", fontSize: "1.1rem" }}>{page.name}</Typography>
							</Box>
						</Box>
					)
				})}
			</Box>
		)
	}

	if (routes && routes.sections) {
		return (
			<Box>
				{
					routes.sections.map((section) => {
						return (
							<Box key={section.name}>
								<Typography variant="h6" sx={{ fontWeight: "bold" }}>{section.name}</Typography>
								<Divider sx={{ backgroundColor: "white", mb: 1.5, borderWidth: "1px" }} />
								<PagesHtml path={routes.path + section.path} pages={section.pages} navigate={props.navigate} />
							</Box>
						)
					})
				}
			</Box>
		)
	} else if (routes && routes.pages) {
		return (
			<PagesHtml path={routes.path} pages={routes.pages} navigate={props.navigate} />
		)
	}

	return <Box>LeftSideRoutes</Box>
}

function LeftSideSection(props: any) {
	const classes = useStyles();
	// TODO: add close icon to toggle drawer + how to show the icons?

	const exitWorksearch = () => {
		remove()
		props.navigate("/password")
	}

	return (
		<Box className={`${classes.leftSection}`}>
			<Box sx={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "space-between" }}>
				<Box sx={{ p: 2, paddingTop: `${LogoWidth - 30}px` }}>
					<LeftSideRoutes navigate={props.navigate} />
				</Box>
				<Box sx={{ alignItems: "flex-end" }}>
					<Box sx={{ p: 2, pb: 0, textAlign: "left" }}>
						{
							LINKS.map((item) => {
								return (
									<Link key={item.name} href={BASE_URL + item.link} target="_blank"
										sx={{ textDecoration: "none", color: "inherit" }}>
										<Box sx={{ display: "flex", cursor: "pointer", mb: 1.25 }}>
											<Icon fontSize="small" sx={{ mr: 1 }}>{item.icon}</Icon>
											<Typography sx={{ fontSize: "1rem" }}>{item.name}</Typography>
										</Box>
									</Link>
								)
							})
						}

						<Box sx={{ display: "flex", cursor: "pointer", mb: 1.25 }}
							onClick={exitWorksearch}
						>
							<Icon fontSize="small" sx={{ mr: 1 }}>exit_to_app_rounded</Icon>
							<Typography sx={{ fontSize: "1rem" }}>Exit</Typography>
						</Box>
					</Box>
					<Box sx={{ p: 1 }}>
						<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "white" }}>
							<Typography variant="subtitle2" sx={{ color: "black" }}>Built by</Typography>
							<Link href="https://hi9.io" target="_blank">
								<img src={Hi9Logo} alt="Hi9" className={classes.hi9Logo} />
							</Link>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

/*
Right Drawer
*/
function RightSideSection(props: any) {
	const classes = useStyles();
	// TODO: add close icon to toggle drawer
	return (
		<Box className={`${classes.rightSection}`}>
			<Box sx={{ pb: 2, pt: 6 }}>
				{/* <Box sx={{ mb: 2 }}>
					<Typography sx={{ fontSize: "1.5rem" }} variant='h5'>
						Popular Prompts
					</Typography>
				</Box> */}
				{RIGHT_ROUTES.map((prompt) => {
					const isCurrentPath = window.location.pathname.includes(prompt.path)
					return (
						<Box key={prompt.text} className={classes.popularPrompt}
							onClick={() => { props.navigate(prompt.path) }}
							sx={{ pl: isCurrentPath ? 1 : 3 }}
						>
							{isCurrentPath && <PlayArrowIcon fontSize="small" sx={{ mt: .25 }} />}
							<Typography sx={{ fontSize: "1.1rem", ml: 1, fontWeight: isCurrentPath ? "bold" : "regular" }}>
								{prompt.text}
							</Typography>
						</Box>
					)
				})}
			</Box>
		</Box>
	)
}

export default function MainLayout(props: any) {
	const classes = useStyles();

	const navigate = useNavigate();

	//#region state
	const [drawers, setDrawers] = React.useState({
		left: false,
		right: false,
	});

	//#endregion

	//#region functions
	const toggleDrawer = (anchor: string, open: boolean) => (event: any) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}

		setDrawers({ ...drawers, [anchor]: open });
	};

	const handleNavigate = (path: string) => {
		navigate(path);
		setDrawers({
			left: false,
			right: false,
		})
	}
	//#endregion

	return (
		<Box className={classes.wrapper} >
			<Box className={classes.nonDrawerSection}>
				<LeftSideSection navigate={handleNavigate} />
			</Box>

			<Box className={classes.middleSection}>
				<Box className={classes.topWrapper}>
					<MenuIcon sx={{ ml: 1 }}
						className={classes.burgerMenuIcon}
						color="primary" fontSize="large"
						onClick={toggleDrawer('left', true)}
					/>
					<Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
						<img src={Logo} alt="Logo" style={{ width: LogoWidth, marginRight: 15 }} />
						<Typography sx={{ fontSize: "35px", fontWeight: "600", lineHeight: "50px" }}>Work Search</Typography>
					</Box>
					<MenuIcon sx={{ mr: 1 }}
						className={classes.burgerMenuIcon}
						color="primary" fontSize="large"
						onClick={toggleDrawer('right', true)}
					/>
				</Box>
				<Box>
					{props.children}
				</Box>
			</Box>

			<Box className={classes.nonDrawerSection}>
				<RightSideSection navigate={handleNavigate} />
			</Box>

			<Drawer
				anchor="left"
				open={drawers["left"]}
				onClose={toggleDrawer("left", false)}
			>
				<Box className={classes.drawerSection}>
					<LeftSideSection navigate={handleNavigate} />
				</Box>
			</Drawer>
			<Drawer
				anchor="right"
				open={drawers["right"]}
				onClose={toggleDrawer("right", false)}
			>
				<Box className={classes.drawerSection}>
					<RightSideSection navigate={handleNavigate} />
				</Box>
			</Drawer>
		</Box >
	)
}
