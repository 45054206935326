import { initializeApp } from "firebase/app";
import {
	getAuth, GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut,
} from "firebase/auth"
import { getFirestore, collection, getDocs, addDoc, doc, deleteDoc, DocumentReference, DocumentData } from "firebase/firestore"

require('dotenv').config()

const firebaseConfig = {
	apiKey: "AIzaSyAtm5jIzu7BMvLDBA9rSWZGoxFP27TOqSI",
	authDomain: "worksearch-384408.firebaseapp.com",
	projectId: "worksearch-384408",
	storageBucket: "worksearch-384408.appspot.com",
	messagingSenderId: "814369549464",
	appId: "1:814369549464:web:49df13e94a0743e3f634ed",
	measurementId: "G-43T0HZG7TN"
};

// Initialize Firebase
const fbApp = initializeApp(firebaseConfig);
const fbDb = getFirestore()
const fbAuth = getAuth()

const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });

const signInWithGoogle = () => signInWithPopup(fbAuth, provider);

async function adminLoggedIn(): Promise<boolean> {
	try {
		await new Promise((resolve, reject) =>
			fbAuth.onAuthStateChanged(
				user => {
					if (user) {
						// User is signed in.
						resolve(user)
					} else {
						// No user is signed in.
						reject('no user logged in')
					}
				},
				// Prevent console error
				error => reject(error)
			)
		)
		return true
	} catch (error) {
		return false
	}
}

async function addUser(data: Record<string, any>) {
	// console.log("addUser", data)
	try {
		const collectionRef = collection(fbDb, "users")
		const newUser : DocumentReference<DocumentData>		= await addDoc(collectionRef, { ...data, createdAt: new Date(), updatedAt: new Date() })
		// console.log('Document written with ID: ', collectionRef.id);
		return newUser.id
	} catch (e) {
		console.error('Error adding document: ', e);
	}
}

async function getUsers() {
	try {
		const collectionRef = collection(fbDb, "users")
		const querySnapshot = await getDocs(collectionRef);
		const users = querySnapshot.docs.map((doc) => {
			return { ...doc.data(), id: doc.id }
		});

		// console.log('Document fetched: ', users);
		return users
	} catch (e) {
		console.error('Error adding document: ', e);
	}
}

async function deleteUser(id: string) {
	// console.log("deleteUser", id)

	try {
		const userRef = doc(fbDb, "users", id)
		await deleteDoc(userRef);
	} catch (e) {
		console.error('Error adding document: ', e);
	}
}

async function logout() {
	try {
		await signOut(fbAuth);
		// console.log('User logged out');
	} catch (e) {
		console.error('Error logging out user: ', e);
	}
}

export {
	fbAuth,
	fbApp,
	signInWithGoogle,
	onAuthStateChanged,
	adminLoggedIn,
	addUser, getUsers, deleteUser,
	logout,
}
