import axios from 'axios'

async function quickJobSearch(data: Record<string, any>) {
	const params = new URLSearchParams(data).toString()

	// const url = "http://192.168.0.8:8080/?" + params
	const url = "https://europe-west2-worksearch-384408.cloudfunctions.net/search_jobs/?" + params

	try {
		const response = await axios.get(url, data)
		// console.log(response.data);
		return response.data
	} catch (error: any) {
		console.error(error)
		throw new Error(error.message)
	}
}

async function commuteJobSearch(data: Record<string, any>) {
	const params = new URLSearchParams(data).toString()

	// const url = "http://192.168.0.8:8081/?" + params
	const url = "https://europe-west2-worksearch-384408.cloudfunctions.net/search_jobs_commute?" + params
	// console.log("url", url)

	try {
		const response = await axios.get(url, data)
		console.log(response.data);
		return response.data
	} catch (error: any) {
		console.error(error)
		throw new Error(error.message)
	}
}

async function getLocationCoordinates(data: Record<string, any>) {
	console.log("getLocationCoordinates", data)
	const params = new URLSearchParams(data).toString()

	const url = "https://europe-west2-worksearch-384408.cloudfunctions.net/get_location_coordinates/?" + params
	// const url = "http://192.168.0.8:8083/?" + params
	console.log("Url", url)
	try {
		const response = await axios.get(url, data)
		console.log(response);
		return response.data
	} catch (error: any) {
		console.error(error)
		throw new Error(error.message)
	}
}

async function extractQueryParameters(data: Record<string, any>) {
	console.log("extractQueryParameters", data)
	const params = new URLSearchParams(data).toString()

	const url = "https://europe-west2-worksearch-384408.cloudfunctions.net/cf_extract_search_parameters/?" + params
	// const url = "http://192.168.0.8:8085/?" + params
	console.log("Url", url)
	try {
		const response = await axios.get(url, data)
		console.log(response.data);
		return response.data
	} catch (error: any) {
		console.error(error)
		throw new Error(error.message)
	}
}

async function quickSkillSearch(data: Record<string, any>) {
	const params = new URLSearchParams(data).toString()

	// const url = "http://192.168.0.8:8083/?" + params
	const url = "https://europe-west2-worksearch-384408.cloudfunctions.net/search_skills/?" + params

	try {
		const response = await axios.get(url, data)
		// console.log(response.data);
		return response.data
	} catch (error: any) {
		console.error(error)
		throw new Error(error.message)
	}
}

async function commuteSkillsJobSearch(data: Record<string, any>) {
	const params = new URLSearchParams(data).toString()

	// const url = "http://192.168.0.8:8082/?" + params
	const url = "https://europe-west2-worksearch-384408.cloudfunctions.net/search_skills_commute?" + params
	// TODO: more than 100 results!
	console.log("url", url)
	try {
		const response = await axios.get(url, data)
		// console.log(response.data);
		return response.data
	} catch (error: any) {
		console.error(error)
		throw new Error(error.message)
	}
}

async function checkPassword(data: Record<string, any>) {
	console.log("checkPassword", data)
	const params = new URLSearchParams(data).toString()

	const url = "https://europe-west2-worksearch-384408.cloudfunctions.net/check_password/?" + params
	console.log("Url", url)
	try {
		const response = await axios.get(url, data)
		console.log(response);
		return response.data
	} catch (error: any) {
		console.error(error)
		throw new Error(error.message)
	}
}

export default {
	quickJobSearch,
	commuteSearch: commuteJobSearch,
	getLocationCoordinates,
	quickSkillSearch,
	commuteSkillsJobSearch,
	checkPassword,
	extractQueryParameters,
}
